<template>
  <div v-if="fandom && fandoms[fandom]">
    <h3>{{ fandoms[fandom].name }}</h3>
    <div class="meta">
      <span class="category meta-tag">
        {{ fandoms[fandom].category.join(", ") }}
      </span>
    </div>
    <table v-if="!loading && !unlock">
      <thead>
        <tr>
          <th class="characters">Nominated Characters</th>
          <th v-if="hasLetters" class="letters">
            Letters
            <span
              class="tooltip c-hand text-normal"
              data-tooltip="Letters submitted to the letters
post before the release of all prompts.
See the help page for more information."
            >
              <sup>
                <i class="fa fa-question-circle" aria-hidden="true"></i>
              </sup>
            </span>
          </th>
        </tr>
      </thead>
      <tr>
        <td class="characters" v-if="!options.hideCharacters">
          <ul>
            <li
              v-for="char in getCharacters(fandom)"
              :key="char"
              :class="{
                highlight: letterChars && letterHasChar(fandom, char),
              }"
            >
              {{ char }}
            </li>
          </ul>
        </td>
        <td
          class="letters"
          v-if="hasLetters && letters && !options.hideLetters"
        >
          <ul>
            <li
              v-for="letter in letters[fandom]"
              :key="letter.username"
              class="letter"
            >
              <a class="user" :href="formatUrl(letter.url)" target="_blank">
                {{ letter.username }} </a
              >&nbsp;
              <button
                class="bookmark-letter"
                @click="toggleLettermark(letter, fandoms[fandom])"
              >
                <span
                  v-if="hasLettermark(letter, fandoms[fandom])"
                  class="fas fa-heart"
                ></span>
                <span v-else class="far fa-heart"></span>
              </button>
              <div class="meta">
                <button
                  class="char-count meta-tag"
                  @click="highlightChars(letter, fandom)"
                >
                  Chars:
                  {{
                    letter.characters === undefined
                      ? "Any"
                      : letter.characters.length
                  }}
                </button>
              </div>
            </li>
          </ul>
        </td>
      </tr>
    </table>

    <!-- prompts -->
    <prompt-table
      v-if="!loading && unlock && prompts[fandom]"
      :forceShow="true"
      :prompts="prompts[fandom]"
    ></prompt-table>
    <div v-else-if="notFound">Fandom not found!</div>
    <div
      v-if="loading || (unlock && fandoms[fandom] && !prompts[fandom])"
      class="loading"
    ></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import PromptTable from "../components/promptTable";
import utils from "../components/utils.js";
export default {
  components: { PromptTable },
  computed: {
    ...mapGetters([
      "promptmarks",
      "options",
      "characters",
      "fandoms",
      "prompts",
      "hasLetters",
      "letters",
      "unlock",
      "isModIn"
    ])
  },
  data() {
    return {
      fandom: "",
      loading: true,
      notFound: false,
      letterChars: {
        fandom: "",
        characters: []
      }
    };
  },
  async beforeMount() {
    this.fandom = this.$route.params.id;
    if (this.unlock || this.isModIn) {
      this.getPrompts(this.fandom);
    } else if (!this.letters) {
      await axios
        .get(`https://tagset2023-default-rtdb.firebaseio.com/letters.json`)
        .then(res => {
          this.$store.commit("setLetters", res.data);
        });
    }
    this.loading = false;
  },
  watch: {
    async $route(to) {
      if (!to.name === "fandom") {
        return;
      }
      this.fandom = this.$route.params.id;

      if (this.unlock || this.isModIn) {
        this.getPrompts(this.fandom);
      } else if (!this.letters) {
        await axios
          .get(`https://tagset2023-default-rtdb.firebaseio.com/letters.json`)
          .then(res => {
            this.$store.commit("setLetters", res.data);
          });
      }

      this.loading = false;
    }
  },
  methods: {
    ...utils
  }
};
</script>

<style lang="scss" scoped>
.bookmark {
  width: 100px;
}
</style>
